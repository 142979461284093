import { DEFAULT_SYSTEM_PROMPT } from './formats/chat.js';

// When this flag is true, this module will generate mock responses instead of
// querying the API. This is helpful for development when one wants to test things
// without making real API requests.
// You may set it to true in your working tree, but PLEASE DON'T COMMIT SUCH A CHANGE
// since that would break the app for anyone else who wants to use it.
const USE_MOCK_RESPONSE = false;
// An example reply from the OpenAI API. It only includes fields which are actually used
// by this module. Real replies have extra info that's not relevant.
const MOCK_RESPONSE = {
  model: 'text-davinci-003',
  choices: [
    {
      text: 'Comment by First Author\nKarma: 1\nFirst comment\n**bold** _italics_\n### Header 3',
      index: 0,
    },
    {
      text: 'Comment by Second Author\nKarma: 2\nSecond comment\n> Block quote\n```\nCode block\n```\n[Hyperlink](#)',
      index: 1,
    },
  ],
};

class Reply {
  // A Reply is an API-agsonstic object containing the completion(s) sent back by the
  // model.
  id;
  completions;

  constructor(id, completions) {
    this.id = id || '';
    this.completions = completions || [];
  }
}

class APIOpenAI {
  // Class for interfacing with OpenAI's API and others that use the same format
  apiKey;
  baseURL = 'https://api.openai.com/v1/';
  endpoint = '/completions';
  maxTokens;
  temperature;

  constructor(
    apiKey,
    baseURL,
    endpoint,
    maxTokens,
    temperature,
  ) {
    if (!apiKey) {
      throw new Error('Must specify API key');
    }
    this.apiKey = apiKey;
    if (baseURL) {
      this.baseURL = baseURL;
    }
    if (endpoint) {
      this.endpoint = endpoint;
    }
    if (maxTokens) {
      this.maxTokens = Number.parseInt(maxTokens);
      if (Number.isNaN(this.maxTokens)) {
        console.error('Cannot max tokens', maxTokens);
        throw new Error('Invalid max tokens');
      }
    }
    if (temperature) {
      this.temperature = Number.parseFloat(temperature);
      if (Number.isNaN(this.temperature)) {
        console.error('Cannot parse temperature', temperature);
        throw new Error('Invalid temperature');
      }
    }
  }

  static fromFormData(formData) {
    return new APIOpenAI(
      formData.get('api-key'),
      formData.get('api-base-url'),
      formData.get('api-endpoint'),
      formData.get('api-max-tokens') || 512,
      formData.get('api-temperature'),
    );
  }

  generate(prompt, model, options) {
    if (!model) {
      throw new Error('Must specify model');
    }
    const chatMode = model.name === 'gpt-4' || model.name === 'gpt-3.5-turbo';

    const request = {
      model: model.name,
      // prompt: prompt,
      // eslint-disable-next-line camelcase
      max_tokens: this.maxTokens,
      temperature: this.temperature,
      stop: model.endTokens
    };

    if (!chatMode) {
      request['prompt'] = prompt;
    } else {
      // TODO: add a system prompt & let users edit the system prompt
      request['messages'] = [{role: 'system', content: DEFAULT_SYSTEM_PROMPT}, {role: 'user', content: prompt}];
    }

    Object.assign(request, options);
    console.debug('API request:', request);
    // When using a mock API response, insert a brief delay to simulate a real request
    let promise = new Promise((resolve, _) => {
      setTimeout(() => resolve(MOCK_RESPONSE), 1000);
    });
    if (!USE_MOCK_RESPONSE) {
      promise = this.sendRequest(request);
    }
    return promise.then(payload => {
      return APIOpenAI.payloadToReply(payload, chatMode);
    });
  }

  sendRequest(request) {
    // Join the base URL and the endpoint, keeping in mind that both, one or neither may
    // contain a slash. There must be a simpler way to do this.
    let url = new URL([this.baseURL, this.endpoint].join('/'));
    // Remove duplicate slashs but only from pathname, not from protocol ('https://')
    url = new URL(url.pathname.replace('///', '/').replace('//', '/'), url);
    const promise = fetch(
      url,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Request-Headers': 'authorization,content-type',
          'Authorization': `Bearer ${this.apiKey}`,
        },
        body: JSON.stringify(request),
      },
    );
    return promise.then(response => {
      console.debug('API response:', response);
      if (!response.ok) {
        return Promise.reject(new Error(
          `Request failed with status ${response.status}: ${response.statusText}`
        ));
      }
      return response.json();
    });
  }

  static payloadToReply(payload, chatMode) {
    // Turn the plain JSON payload returned by the API into a generic reply object that
    // other parts of the app can use without caring about where it came from.
    return new Reply(
      // Give the reply a pseudo-unique ID since React wants to be able to distinguish
      // between comments. If the payload contains and ID, use that. Otherwise, fall
      // back to the current timestamp. `crypto.randomUUID()` would also fit the bill
      // but isn't available in all contexts (e.g. unit tests).
      payload.id || Date.now().toString(),
      payload.choices.map((o) => {
        return chatMode ? o.message.content : o.text;
      })
    );
  }
}

export {
  MOCK_RESPONSE,
  APIOpenAI,
  Reply,
};
