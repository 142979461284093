class Model {
  name;
  endTokens;

  constructor(name, endTokens) {
    this.name = name;
    this.endTokens = endTokens;
  }
}

function modelFromFormData(formData) {
  const name = formData.get('api-model');
  return new Model(name);
}

export {
  modelFromFormData,
};
