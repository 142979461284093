// Most frequent commenters on the Alignment Forum according to the
// Alignment Research Dataset: https://github.com/moirage/alignment-research-dataset

// They'll be shown to the user in the same order as this array.
const FREQUENT_COMMENTERS = [
  {name: 'abramdemski', numComments: 68},
  {name: 'adamShimi', numComments: 125},
  {name: 'avturchin', numComments: 88},
  {name: 'Ben Pace', numComments: 129},
  {name: 'capybaralet', numComments: 37},
  {name: 'Charlie Steiner', numComments: 218},
  {name: 'Chris_Leong', numComments: 60},
  {name: 'cousin_it', numComments: 75},
  {name: 'Dagon', numComments: 54},
  {name: 'Daniel Kokotajlo', numComments: 156},
  {name: 'DanielFilan', numComments: 74},
  {name: 'Davidmanheim', numComments: 41},
  {name: 'Donald Hobson', numComments: 115},
  {name: 'ESRogs', numComments: 50},
  {name: 'evhub', numComments: 81},
  {name: 'G Gordon Worley III', numComments: 124},
  {name: 'Gurkenglas', numComments: 96},
  {name: 'gwern', numComments: 58},
  {name: 'habryka', numComments: 58},
  {name: 'jessicata', numComments: 46},
  {name: 'John_Maxwell', numComments: 57},
  {name: 'johnswentworth', numComments: 78},
  {name: 'Kaj_Sotala', numComments: 30},
  {name: 'Koen.Holtman', numComments: 41},
  {name: 'Matthew Barnett', numComments: 27},
  {name: 'NicholasKross', numComments: 83},
  {name: 'ofer', numComments: 61},
  {name: 'Pattern', numComments: 138},
  {name: 'paulfchristiano', numComments: 87},
  {name: 'Quintin Pope', numComments: 29},
  {name: 'Raemon', numComments: 45},
  {name: 'Rafael Harth', numComments: 52},
  {name: 'Ramana Kumar', numComments: 45},
  {name: 'riceissa', numComments: 28},
  {name: 'Richard_Ngo', numComments: 41},
  {name: 'Rob Bensinger', numComments: 60},
  {name: 'Rohin Shah', numComments: 358},
  {name: 'romeostevensit', numComments: 54},
  {name: 'Sammy Martin', numComments: 29},
  {name: 'Scott Garrabrant', numComments: 32},
  {name: 'shminux', numComments: 60},
  {name: 'Steven Byrnes', numComments: 119},
  {name: 'Stuart_Armstrong', numComments: 42},
  {name: 'tailcalled', numComments: 41},
  {name: 'Trevor1', numComments: 120},
  {name: 'TurnTrout', numComments: 80},
  {name: 'Vanessa Kosoy', numComments: 118},
  {name: 'Vaniver', numComments: 39},
  {name: 'Wei_Dai', numComments: 151},
  {name: 'William_S', numComments: 39},
];

export { FREQUENT_COMMENTERS };
