import Container from 'react-bootstrap/Container';
import { useState } from 'react';

import { AboutOverlay } from './components/about.js';
import { ForumComments } from './components/comment.js';
import { PostForm } from './components/post.js';
import './App.scss';

function ErrorMessage({message}) {
  if (!message) {
    return <></>;
  }
  return (
    <p className='text-danger'>
      {message}
    </p>
  );
}

function App() {
  const [comments, setComments] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  const deleteComment = (key) => {
    setComments((prevComments) =>
      prevComments.filter((comment) => comment.key !== key)
    );
  };

  return (
    <div>
      <header>
        <div className='af-banner align-items-center'>
          <AboutOverlay />
          <h1>SCAFFOLD</h1>
          <p><i>An AI Alignment Feedback Tool</i> | v1.0.1</p>
        </div>
      </header>
      <div className='banner-spacer'/>
      <Container fluid='lg'>
        <PostForm
          existingComments={comments}
          setComments={setComments}
          setErrorMessage={setErrorMessage}
        />
        <h2>Comments</h2>
        <ErrorMessage message={errorMessage} />
        <ForumComments comments={comments} onDelete={deleteComment}/>
      </Container>
    </div>
  );
}

export default App;
