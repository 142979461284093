import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { useState } from 'react';
import { IconContext } from 'react-icons';
import { BsInfoCircle } from 'react-icons/bs';

function AboutOverlay() {
  const [show, setShow] = useState(false);

  return (
    <>
      <Button className='about-button' size='lg' onClick={() => setShow(true)}>
        <IconContext.Provider value={{className: 'about-icon' }}>
          <div>
            <BsInfoCircle />
          </div>
        </IconContext.Provider>
      </Button>
      <Offcanvas show={show} onHide={() => setShow(false)}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            About SCAFFOLD
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <p>
            SCAFFOLD is a tool to provide feedback on your ideas in the form of comments
            similar to those on the AI Alignemnt Forum and LessWrong. Outside input can
            be valuable for refining one's thoughts and plans, but it takes time to
            articulate them such that others can engage with them. SCAFFOLD offers quick
            input early in the thought process by pointing out new directions to
            consider, finding flaws in arguments, and more!
          </p>
          <p>
            If you have any feedback about what you like or what can be improved, <a
              href='https://docs.google.com/forms/d/e/1FAIpQLSdCNbdyLDEPUSGSdS4MEMGzj1IHwGsN1aThX3976p6ZWuwIrw/viewform?usp=sharing'>
                please leave your thoughts here</a>.
          </p>
          <p>
            SCAFFOLD was developed as part of <a href="https://aisafety.camp/">AI Safety
            Camp</a> by Robert Gambee with assistance from Nicholas Kees Dupuis, Hunar
            Batra and Julia Persson. The name is an acronym for Simulated Comments from
            the Alignment Forum For Original Literature Development. The name and some
            aspects of the UI were taken from an earlier prototype created by Gabriel
            Mukobi.
          </p>
          <p>
            SCAFFOLD is not affiliated with the AI Alignment Forum or LessWrong.
            Remember that all comments are generated by an AI model, not real people.
            Consult the relevant community guidelines regarding AI-generated content
            before posting comments from this tool elsewhere.
          </p>
          <p>
            &copy; 2023
          </p>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export {
  AboutOverlay,
};
