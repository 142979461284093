import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import ReactMarkdown from 'react-markdown';

import { GrTrash } from 'react-icons/gr';

import { FREQUENT_COMMENTERS } from '../commenters.js';

import './../App.scss';

function CommentAuthorSelect({commenters}) {
  const commenterListId = 'id-commenter-list';
  return (
    <>
      <Form.Control
        name='comment-author'
        placeholder='AI generated'
        list={commenterListId}
      />
      <datalist id={commenterListId}>
        {(commenters || []).map(comm => (
          <option key={comm.name} value={comm.name}>{comm.name}</option>
        ))}
      </datalist>
    </>
  );
}

function CommentConditioning() {
  const labelColWidth = '1';
  const rowClasses = 'my-3';

  return (
    <Container>
      <Row className={rowClasses + ' align-items-end'}>
        <Col xs='12' xxl='6'>
          <h2>Comment Conditioning</h2>
        </Col>
        <Col xs='12' xxl='6' className='mb-2'>
          <Form.Text>
            Optionally condition the comments generated by the model
          </Form.Text>
        </Col>
      </Row>
      <Row className={rowClasses}>
        <Col>
          <Form.Group as={Row} controlId='id-comment-author'>
            <Form.Label column md={2 * labelColWidth}>Author</Form.Label>
            <Col md={12 - 2 * labelColWidth}>
              <CommentAuthorSelect commenters={FREQUENT_COMMENTERS} />
            </Col>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group as={Row} controlId='id-comment-karma'>
            <Form.Label column md={2 * labelColWidth}>Karma</Form.Label>
            <Col md={12 - 2 * labelColWidth}>
              <Form.Control name='comment-karma' type='number' placeholder='AI generated' />
            </Col>
          </Form.Group>
        </Col>
      </Row>
      <Row className={rowClasses}>
        <Col>
          <Form.Group as={Row} controlId='id-comment-body'>
            <Form.Label column md={labelColWidth}>Body</Form.Label>
            <Col md={12 - labelColWidth}>
              <Form.Control name='comment-body' as='textarea' placeholder='Start of comment' />
            </Col>
          </Form.Group>
        </Col>
      </Row>
    </Container>
  );
}

function ForumComments({comments, onDelete}) {
  return (
    <div>
      {comments.map((comment) => (
        <Container key={comment.key} className='border py-3 my-3'>
          <Row>
            <Col xs='auto'>
              <strong>{comment.author || 'Unknown author'}</strong>
            </Col>
            <Col xs='auto'>
              &lt; {Number.isNaN(comment.karma) ? '?' : comment.karma} &gt;
            </Col>
            <Col className='d-flex justify-content-end'>
              <button className='delete-button'>
                <GrTrash onClick={() => onDelete(comment.key)}/> 
              </button>
            </Col>
          </Row>
          <Row className='comment-body'>
            <ReactMarkdown children={comment.body} />
          </Row>
        </Container>
      ))}
    </div>
  );
}

export {
  CommentConditioning,
  ForumComments,
};
