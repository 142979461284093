import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useState, useEffect, useMemo } from 'react';

function APIConfiguration() {
  // `temperatureIndicator` is the visual string shown to the user, not the numerical
  // value used behind the scenes.
  const [temperatureIndicator, setTemperatureIndicator] = useState('1.0');

  function onTemperatureChange(event) {
    const s = Number.parseFloat(event.target.value).toLocaleString(
      undefined,
      {style: 'decimal', minimumFractionDigits: 1, maximumFractionDigits: 1},
    );
    setTemperatureIndicator(s);
  }

  // Add this function to handle form field changes
  function onFieldChange(event) {
    const { name, value } = event.target;
    localStorage.setItem(name, value);
  }

  const rowClasses = 'my-3';
  const labelColWidth = '4';
  const controlColWidth = '8';

  const formFields = useMemo(() => ([
    {
      controlId: 'id-api-config-key',
      label: 'API Key',
      name: 'api-key',
      type: 'password',
      placeholder: 'sk-123abc',
      required: true,
    },
    {
      controlId: 'id-api-model',
      label: 'Model',
      name: 'api-model',
      placeholder: 'code-davinci-002',
    },
    {
      controlId: 'id-api-base-url',
      label: 'Base URL',
      name: 'api-base-url',
      placeholder: 'https://api.openai.com/v1/',
    },
    {
      controlId: 'id-api-endpoint',
      label: 'Endpoint',
      name: 'api-endpoint',
      placeholder: '/completions',
    },
    {
      controlId: 'id-api-max-tokens',
      label: 'Max Tokens',
      name: 'api-max-tokens',
      type: 'number',
      placeholder: '512',
    },
  ]), []);

  // Retrieve values from local storage and set as default
  useEffect(() => {
    formFields.forEach(field => {
      const value = localStorage.getItem(field.name);
      if (value) {
        document.getElementsByName(field.name)[0].value = value;
      }
    });
  }, [formFields]);

  return (
    <Container>
      <Row className={rowClasses}>
        <h2>API Configuration</h2>
      </Row>
      {formFields.map(field => (
        <Form.Group as={Row} className={rowClasses} controlId={field.controlId} key={field.controlId}>
          <Form.Label column xs={labelColWidth}>{field.label}</Form.Label>
          <Col xs={controlColWidth}>
            <Form.Control
              name={field.name}
              type={field.type}
              required={field.required}
              placeholder={field.placeholder}
              onChange={onFieldChange} // Add this line
            />
          </Col>
        </Form.Group>
      ))}
      <Form.Group as={Row} className={rowClasses + ' align-items-end'} controlId='id-api-temperature'>
        <Form.Label column xs={labelColWidth}>Temperature</Form.Label>
        <Col xs={controlColWidth}>
          <Row className='align-items-start'>
            <Col>
              <Form.Range name='api-temperature' onChange={onTemperatureChange} min='0' max='2' step='0.1' />
            </Col>
            <Col xs='auto'>
              <span>{temperatureIndicator}</span>
            </Col>
          </Row>
        </Col>
      </Form.Group>
    </Container>
  );
}

export {
  APIConfiguration
};
