import {
  formatPromptLWCommonCrawl,
  parseCommentsLWCommonCrawl,
} from './lwCommonCrawl.js';
import {
  formatPromptLWReverseEngineered,
  parseCommentsLWReverseEngineered,
} from './lwReverseEngineered.js';

class Format {
  // The user-facing name of this format. Must be unique among all other formats.
  name;
  // A function that takes a ForumPost and ForumComment and returns a string
  formatPrompt;
  // A function that takes a reply and returns an array of ForumComments
  parseComments;

  constructor (name, formatPrompt, parseComments) {
    if (!name) {
      throw new Error('Must specify format name');
    }
    if (!formatPrompt) {
      throw new Error('Must specify prompt formatting function');
    }
    if (!parseComments) {
      throw new Error('Must specify comment parsing function');
    }

    this.name = name;
    this.formatPrompt = formatPrompt;
    this.parseComments = parseComments;
  }
}

// List of formats the user can select. Formats will be sorted be sorted alphabetically
// by user-facing name, so use a prefix to group similar formats together.
// For documentation on how to edit formats or add new ones, see docs/prompt.md.
const FORMATS = [
  new Format('LWCommonCrawl', formatPromptLWCommonCrawl, parseCommentsLWCommonCrawl),
  new Format('LWReverseEngineered', formatPromptLWReverseEngineered, parseCommentsLWReverseEngineered),
];

function getFormatNames() {
  return FORMATS.map(fmt => fmt.name).sort();
}

function lookUpFormatByName(name) {
  return FORMATS.find(fmt => fmt.name === name);
}

// Select LWReverseEngineered format by default
const DEFAULT_FORMAT = lookUpFormatByName('LWReverseEngineered');

export {
  DEFAULT_FORMAT,
  FORMATS,
  getFormatNames,
  lookUpFormatByName,
};
