import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import { useState } from 'react';

import { APIConfiguration } from './apiConfig.js';
import {
  ManualPrompt,
  PromptConfiguration,
  PromptPreview,
} from './promptConfig.js';
import { CommentConditioning } from './comment.js';
import { onPostFormChange, onPostFormSubmit } from '../formProcessing.js';

function UserPost() {
  const labelColWidth = 1;
  const rowClasses = 'my-3';

  return (
    <Container>
      <Row className={rowClasses}>
        <h2>Your Post</h2>
      </Row>
      <Row className={rowClasses}>
        <Col>
          <Form.Group as={Row} controlId='id-post-title'>
            <Form.Label column md={labelColWidth}>Title</Form.Label>
            <Col md={12 - labelColWidth}>
              <Form.Control name='post-title' placeholder='Untitled' />
            </Col>
          </Form.Group>
        </Col>
      </Row>
      <Row className={rowClasses}>
        {/*
          This row is split into multiple columns, so the width of the label
          column needs to be multiplied to match the other rows.
        */}
        <Col>
          <Form.Group as={Row} controlId='id-post-author'>
            <Form.Label column md={3 * labelColWidth}>Author</Form.Label>
            <Col md={12 - 3 * labelColWidth}>
              <Form.Control name='post-author' placeholder='Anonymous' />
            </Col>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group as={Row} controlId='id-post-tags'>
            <Form.Label column md={2 * labelColWidth}>Tags</Form.Label>
            <Col md={12 - 2 * labelColWidth}>
              <Form.Control name='post-tags' placeholder='First tag, Second tag' />
            </Col>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group as={Row} controlId='id-post-karma'>
            <Form.Label column md={3 * labelColWidth}>Karma</Form.Label>
            <Col md={12 - 3 * labelColWidth}>
              <Form.Control name='post-karma' type='number' placeholder='100' />
            </Col>
          </Form.Group>
        </Col>
      </Row>
      <Row className={rowClasses}>
        <Col>
          <Form.Group as={Row} controlId='id-post-body'>
            <Form.Label column md={labelColWidth}>Post</Form.Label>
            <Col md={12 - labelColWidth}>
              <Form.Control
                name='post-body'
                as='textarea'
                rows={5}
                placeholder='Contents of post' />
            </Col>
          </Form.Group>
        </Col>
      </Row>
    </Container>
  );
}

function SubmitButton({generating}) {
  let buttonContents = 'Submit';
  if (generating) {
    // When waiting for a response, replace the button text and add a spinner
    buttonContents = (
      <>
        <Spinner
          as="span"
          animation="border"
          className='me-2'
          size="sm"
          role="status"
          aria-hidden="true"
        />
        Generating...
      </>
    );
  }

  return (
    <Button
      type='submit'
      disabled={generating}
    >
      {buttonContents}
    </Button>
  );
}

function PostForm({existingComments, setComments, setErrorMessage}) {
  const [manualPrompt, setManualPrompt] = useState('');
  const [manualPromptEnabled, setManualPromptEnabled] = useState(false);
  const [promptPreview, setPromptPreview] = useState('');
  const [previewVisible, setPreviewVisible] = useState(false);
  const [generating, setGenerating] = useState(false);

  let promptComponents = (
    <>
      <UserPost />
      <CommentConditioning />
    </>
  );
  if (manualPromptEnabled) {
    promptComponents = (
      <ManualPrompt
        manualPrompt={manualPrompt}
        setManualPrompt={setManualPrompt}
      />
    );
  }

  return (
    <Form
      onInput={(event) => onPostFormChange(
        event,
        manualPromptEnabled,
        setManualPrompt,
        promptPreview,
        setPromptPreview,
        setErrorMessage,
      )}
      onSubmit={(event) => onPostFormSubmit(
        event,
        manualPromptEnabled,
        existingComments,
        setGenerating,
        setComments,
        setErrorMessage,
      )}
    >
      <Row>
        <Col lg='8'>
          {promptComponents}
        </Col>
        <Col lg='4'>
          <APIConfiguration />
          <PromptConfiguration
            manualPromptEnabled={manualPromptEnabled}
            setManualPromptEnabled={setManualPromptEnabled}
            previewVisible={previewVisible}
            setPreviewVisible={setPreviewVisible}
          />
        </Col>
      </Row>
      <SubmitButton generating={generating} />
      <PromptPreview
        promptPreview={promptPreview}
        visible={!manualPromptEnabled && previewVisible}
      />
    </Form>
  );
}

export {
  PostForm,
};
