import { modelFromFormData } from './model.js';
import { lookUpFormatByName } from './formats/common.js';
import { APIOpenAI } from './api.js';

class ForumPost {
  title;
  author;
  tags;
  karma;
  body;

  constructor(title, author, tags, karma, body) {
    this.title = title || '';
    this.author = author || '';
    this.tags = (tags || '').split(',').map(s => s.trim());
    this.karma = Number.parseInt(karma);
    this.body = body || '';
  }

  static fromFormData(formData) {
    return new ForumPost(
      formData.get('post-title'),
      formData.get('post-author'),
      formData.get('post-tags'),
      formData.get('post-karma'),
      formData.get('post-body'),
    );
  }
}

class ForumComment {
  author;
  karma;
  body;
  key;

  constructor(author, karma, body, key) {
    this.author = author || '';
    this.karma = Number.parseInt(karma);
    this.body = body || '';
    this.key = key || '';
  }

  static fromFormData(formData) {
    return new ForumComment(
      formData.get('comment-author'),
      formData.get('comment-karma'),
      formData.get('comment-body'),
    );
  }

  empty() {
    return !this.author && Number.isNaN(this.karma) && !this.body;
  }
}

function createPrompt(
  data,
  manualPromptEnabled,
  setErrorMessage,
) {
  if (manualPromptEnabled) {
    return data.get('manual-prompt');
  }
  const post = ForumPost.fromFormData(data);
  const comment = ForumComment.fromFormData(data);
  const format = lookUpFormatByName(data.get('prompt-format'));
  if (format === undefined) {
    if (setErrorMessage === undefined) {
      // Don't throw an error, just return null. This function can be called from an
      // onChange listener, which can fire many times a second (whenever the user
      // presses a key). In that situation, we don't want to pollute the console with
      // many repeats of the same error.
      return null;
    }
    const errorMessage = 'Unrecognized format name';
    console.error(`${errorMessage}: ${data.get('prompt-format')}`);
    setErrorMessage(errorMessage);
    throw new Error(errorMessage);
  }
  return format.formatPrompt(post, comment);
}

function onPostFormChange(
  event,
  manualPromptEnabled,
  setManualPrompt,
  promptPreview,
  setPromptPreview,
) {
  const data = new FormData(event.target.form);
  const prompt = createPrompt(data, manualPromptEnabled);
  if (prompt === null) {
    // Something went wrong while constructing the prompt. For now, ignore the error and
    // don't update either the manual prompt or prompt preview. The user will be alerted
    // to the error later when they try to submit the form.
    return;
  }

  if (manualPromptEnabled) {
    setManualPrompt(prompt);
  } else {
    // If this change modified the prompt preview, also update the manual prompt.
    // This excludes events from changes to the API config, for which we don't want to
    // overwrite the manual prompt.
    if (promptPreview !== prompt) {
      setManualPrompt(prompt);
    }
    // Prompt preview is redundant in manual mode, so only set it if manual mode is off
    setPromptPreview(prompt);
  }
}

function onPostFormSubmit(
  event,
  manualPromptEnabled,
  existingComments,
  setGenerating,
  setComments,
  setErrorMessage,
) {
  event.preventDefault();
  let api;
  const data = new FormData(event.target);
  try {
    api = APIOpenAI.fromFormData(data);
  } catch (error) {
    setErrorMessage(error.toString());
    throw error;
  }
  const format = lookUpFormatByName(data.get('prompt-format'));
  if (format === undefined) {
    const errorMessage = 'Unrecognized format name';
    console.error(`${errorMessage}: ${data.get('prompt-format')}`);
    setErrorMessage(errorMessage);
    throw new Error(errorMessage);
  }
  const model = modelFromFormData(data);
  const comment = ForumComment.fromFormData(data);
  const prompt = createPrompt(
    data,
    manualPromptEnabled,
    setErrorMessage,
  );
  setGenerating(true);
  // Set generating back to false in the event of a timeout so the submit button isn't
  // permanently disabled.
  const timeoutID = setTimeout(() => setGenerating(false), 30 * 1000);
  const replyPromise = api.generate(prompt, model);
  replyPromise.then((reply) => {
    setGenerating(false);
    clearTimeout(timeoutID);
    const newComments = format.parseComments(reply, comment);
    setComments(newComments.concat(existingComments));
    setErrorMessage('');
    return reply;
  }).catch((error) => {
    setGenerating(false);
    clearTimeout(timeoutID);
    console.error(error);
    setErrorMessage(error.toString());
    return error;
  });
}

export {
  ForumComment,
  ForumPost,
  onPostFormChange,
  onPostFormSubmit,
};
