import Col from 'react-bootstrap/Col';
import Collapse from 'react-bootstrap/Collapse';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

import { getFormatNames, DEFAULT_FORMAT } from '../formats/common.js';

function PromptConfiguration({
  manualPromptEnabled,
  setManualPromptEnabled,
  previewVisible,
  setPreviewVisible,
}) {
  const rowClasses = 'my-3';
  const labelColWidth = '4';
  const controlColWidth = '8';
  const formatSelectName = 'prompt-format';

  function onFormatChanged(event) {
    localStorage.setItem(formatSelectName, event.target.value);
  }

  const formatOptions = getFormatNames().map(
    formatName => <option key={formatName} >{formatName}</option>
  );

  const selectedFormat = (
    localStorage.getItem(formatSelectName)
    || (DEFAULT_FORMAT && DEFAULT_FORMAT.name)
  );

  return (
    <Container>
      <Row className={rowClasses}>
        <h2>Prompt Configuration</h2>
      </Row>
      <Form.Group as={Row} className={rowClasses} controlId='prompt-format'>
        <PromptToggles
          manualPromptEnabled={manualPromptEnabled}
          setManualPromptEnabled={setManualPromptEnabled}
          previewVisible={previewVisible}
          setPreviewVisible={setPreviewVisible}
        />
        <Form.Label column xs={labelColWidth}>Format</Form.Label>
        <Col xs={controlColWidth}>
          <Form.Select
            name={formatSelectName}
            defaultValue={selectedFormat}
            disabled={manualPromptEnabled}
            onChange={onFormatChanged}
          >
            {formatOptions}
          </Form.Select>
        </Col>
      </Form.Group>
    </Container>
  );
}

function PromptToggles({
  manualPromptEnabled,
  setManualPromptEnabled,
  previewVisible,
  setPreviewVisible,
}) {
  return (
    <Row className='my-3'>
      <Col xs='auto'>
        <Form.Switch
          id='id-manual-prompt-enabled'
          onClick={() => setManualPromptEnabled(!manualPromptEnabled)}
          label='Manual prompt'
        />
      </Col>
      <Col xs='auto'>
        <Form.Switch
          id='id-prompt-preview-visible'
          onClick={() => setPreviewVisible(!previewVisible)}
          label='Prompt preview'
          disabled={manualPromptEnabled}
          aria-controls='id-prompt-preview'
          aria-expanded={previewVisible}
        />
      </Col>
    </Row>
  );
}

function ManualPrompt({manualPrompt, setManualPrompt}) {
  const rowClasses = 'my-3';

  function onTextAreaChange(event) {
    setManualPrompt(event.target.value);
  }

  return (
    <Container>
      <Row className={rowClasses}>
        <h2>Manual Prompt</h2>
      </Row>
      <Row className={rowClasses}>
        <Form.Control
          name='manual-prompt'
          as='textarea'
          rows='10'
          value={manualPrompt}
          onChange={onTextAreaChange}
          placeholder='Model prompt'
        />
      </Row>
    </Container>
  );
}

function PromptPreview({promptPreview, visible}) {
  return (
    <Collapse in={visible}>
      <div className='my-3' id='id-prompt-preview'>
        <h2 className='mt-0'>Prompt Preview</h2>
        <pre className='border p-3 prompt-preview'>{promptPreview}</pre>
      </div>
    </Collapse>
  );
}

export {
  ManualPrompt,
  PromptConfiguration,
  PromptPreview,
  PromptToggles,
};
